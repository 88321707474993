import React, { useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";

import Card from "./Card";
import { ThemeContext } from "../../ThemeContext";
const cardsContent = [
  {
    title: "Imposto de Renda Pessoa Física",
    shortBody:
      "oferecemos serviço especializado e individualizado, garantindo que todos os detalhes sejam analisados e declarados.",
    body:
      "oferecemos serviço especializado e individualizado, garantindo que todos os detalhes sejam analisados e declarados. Elaboramos Livro Caixa Digital de Produtor Rural, obrigatório a partir de 2020. Também declarações de espólio e de final de espólio, declarações com recebimentos de reclamatórias trabalhistas (rendimentos recebidos de forma cumulativamente) e outras específicas.",
  },
  {
    title: "* Consultoria empresarial",
    body:
      "proporcionar ao empresário ideias e conhecimento para atingir um mesmo objetivo – o sucesso do cliente.",
  },
  {
    title: "Contabilidade",
    body:
      "apurar os resultados de cada cliente de forma individualizada, proporcionando informações que possam ser utilizadas na gestão do negócio.",
  },
];

const Services = ({ isServicesPage }) => {
  const data = useStaticQuery(graphql`
    query {
      service1: file(relativePath: { eq: "services-1.jpg" }) {
        childImageSharp {
          fixed(width: 360, height: 280) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      service2: file(relativePath: { eq: "services-2.jpg" }) {
        childImageSharp {
          fixed(width: 360, height: 280) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      service3: file(relativePath: { eq: "services-3.jpg" }) {
        childImageSharp {
          fixed(width: 360, height: 280) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const { mobile } = useContext(ThemeContext);

  return (
    <>
      <h1 style={styles.title}>Serviços</h1>
      {isServicesPage && (
        <div>
          <h2 style={styles.subtitle}>Áreas de atuação.</h2>
          <div style={styles.subtitleText}>
            <p>
              Atendemos a industrias moveleiras e calçadistas, comércios e os
              mais variados e serviços , Prestação de serviços contábeis, fiscal
              e RH, com todo suporte nestas áreas para dar segurança aos
              clientes.
            </p>
            <p>
              Suporte a Pessoas Físicas, administração de registros domésticos,
              e-social, declarações de Pessoa Física, inclusive com escrituração
              de LCDPR, Declarações de espolio e de final de espolio, apuração
              de ganhos de capital e de renda variável.{" "}
            </p>
            <p>
              Suporte para regularização junto ao fisco, tanto PJ, quanto PF.
              Declaração de ITR
            </p>
          </div>
        </div>
      )}
      <div style={styles.cardsWrapper({ mobile })}>
        {cardsContent.map((card, i) => (
          <Card
            key={card.title}
            fixed={data[`service${i + 1}`].childImageSharp.fixed}
            text={card}
            extended={isServicesPage}
            oddIndex={i % 2 !== 0}
          />
        ))}
      </div>
    </>
  );
};

const styles = {
  cardsWrapper: ({ mobile }) => ({
    display: "flex",
    flexDirection: mobile ? "column" : "row",
    justifyContent: "center",
    flexWrap: "wrap",
    alignItems: "center",
  }),
  title: {
    textAlign: "center",
    paddingBottom: "16px",
  },
  subtitle: {
    textAlign: "left",
  },
  subtitleText: {
    textAlign: "left",
    padding: "18px 0",
  },
};

export default Services;
