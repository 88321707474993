import React, { useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";

import { ThemeContext } from "../../ThemeContext";

import "react-awesome-slider/dist/styles.css";
import "./carousel.css";

const AutoplaySlider = withAutoplay(AwesomeSlider);

export const Carousel = () => {
  const data = useStaticQuery(graphql`
    query {
      service1: file(relativePath: { eq: "services-1.jpg" }) {
        childImageSharp {
          fixed(width: 1200, height: 540) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      service2: file(relativePath: { eq: "services-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, maxHeight: 540) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      service3: file(relativePath: { eq: "services-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, maxHeight: 540) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const { colors } = useContext(ThemeContext);

  return (
    <AutoplaySlider
      play={false}
      cancelOnInteraction={false} // should stop playing on user interaction
      interval={6000}
      bullets={true}
      style={{
        position: "relative",
        height: "540px",
        padding: "12px",
      }}
    >
      <div
        data-src={data.service1.childImageSharp.fixed.src}
        style={{ maxHeight: "540px" }}
        id="special-img"
      >
        <div style={{ ...styles.textOverlay }}>
          <div className="img-item">
            <div className="item-title" style={styles.title}>
              <p>Probus</p>
            </div>
            <div className="item-venue" style={styles.body}>
              <p>Excelência contábil a mais de 60 anos</p>
            </div>
          </div>
        </div>
      </div>
      <div
        data-src={data.service2.childImageSharp.fluid.src}
        style={{ maxHeight: "540px" }}
      >
        <div
          style={{
            ...styles.textOverlay,
          }}
        >
          <div className="img-item">
            <div className="item-title" style={styles.title}>
              <p>Serviços</p>
            </div>
            <div className="item-venue" style={styles.body}>
              <p>serviços contábeis, fiscais e RH para PJ e PF</p>
            </div>
          </div>
        </div>
      </div>
      <div
        data-src={data.service3.childImageSharp.fluid.src}
        style={{ maxHeight: "540px" }}
      >
        <div
          style={{
            ...styles.textOverlay,
            color: colors.green,
          }}
        >
          <div className="img-item">
            <div className="item-title" style={styles.title}>
              <p>Equipe</p>
            </div>
            <div className="item-venue" style={styles.body}>
              <p>Profissionais qualificados com vasta esperiência</p>
            </div>
          </div>
        </div>
      </div>
    </AutoplaySlider>
  );
};

const styles = {
  textOverlay: {
    position: "absolute",
    zIndex: 9999,
    bottom: "10%",
    right: "40px",
    color: "white",
  },
  title: {
    fontSize: "36px",
  },
  body: {
    fontSize: "24px",
  },
};
