import React, { useState, useContext } from "react";

import Img from "gatsby-image";
import { ThemeContext } from "../../ThemeContext";
import { Link } from "gatsby";

const titleHeight = 65;
const margin = 50;

const Card = ({
  fixed,
  text: { title, body, shortBody },
  extended,
  oddIndex,
}) => {
  const [showText, setShowText] = useState(false);
  const textBodyRef = React.useRef();
  const { colors, mobile } = useContext(ThemeContext);

  const wrapperStyles = () => {
    const height = {
      height: `${
        textBodyRef.current &&
        textBodyRef.current.getBoundingClientRect().height +
          margin +
          titleHeight
      }px`,
    };

    return showText
      ? {
          ...styles.textWrapper({ colors }),
          ...height,
          transform: `translateY(-${
            textBodyRef.current.getBoundingClientRect().height +
            titleHeight +
            margin / 2
          }px)`,
        }
      : {
          ...styles.textWrapper({ colors }),
          ...height,
        };
  };

  return extended ? (
    <div style={styles.extendedCard({ oddIndex, colors })}>
      {!mobile && (
        <div style={styles.container}>
          <Img
            fixed={fixed}
            style={{
              borderRadius: "8px",
            }}
          />
        </div>
      )}
      <div style={styles.container}>
        <p style={styles.textTitle({ colors, extended })}>{title}</p>
        <p>{body}</p>
      </div>
    </div>
  ) : (
    <Link to="/services/" style={{ textDecoration: "none" }}>
      <div
        style={styles.card}
        onMouseEnter={() => setShowText(true)}
        onMouseLeave={() => setShowText(false)}
      >
        <Img fixed={fixed} />
        <div style={wrapperStyles()}>
          <p style={styles.textTitle({ colors })}>{title}</p>
          <p ref={textBodyRef}>{shortBody || body}</p>
        </div>
      </div>
    </Link>
  );
};

const styles = {
  card: {
    height: "280px",
    width: "304px",
    overflow: "hidden",
    fontFamily: "bahnschrift",
    boxShadow: "0 0 8px 0 rgba(0,0,0,0.5)",
    borderRadius: "8px",
    margin: "10px 8px",
  },
  extendedCard: ({ oddIndex, colors }) => ({
    display: "flex",
    flexDirection: oddIndex ? "row-reverse" : "row",
    alignItems: "center",
    fontFamily: "bahnschrift",
    boxShadow: "0 0 8px 0 rgba(0,0,0,0.5)",
    borderRadius: "8px",
    margin: "16px 0",
    backgroundColor: colors.lightBlue,
    color: colors.white,
    fontSize: "16px",
  }),
  container: {
    padding: "10px",
    textAlign: "justify",
  },

  textWrapper: ({ colors }) => ({
    backgroundColor: colors.lightBlue,
    opacity: 0.92,
    color: colors.white,
    transform: `translateY(-${titleHeight}px)`,
    transition: "transform 0.3s ease-in",
    padding: "0 12px",
  }),
  textTitle: ({ colors, extended }) => ({
    height: `${titleHeight}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "20px",
    paddingTop: "4px",
    fontWeight: "bold",
    textAlign: "center",
    backgroundColor: !extended && colors.blue,
    margin: "0 -12px 8px",
  }),
};

export default Card;
