import React from "react";

import Layout from "../components/layout";

import Services from "../components/Services";
import { Carousel } from "../components/Carousel";
import { ContactUsForm } from "../components/ContactUsForm";

const Space = ({ top }) => <div style={{ marginTop: top }} />;

const IndexPage = ({ pageTitle }) => (
  <Layout pageTitle={pageTitle}>
    <Carousel />
    <Space top={50} />
    <Services />
    <ContactUsForm />
  </Layout>
);

IndexPage.defaultProps = {
  pageTitle: "Home",
};
export default IndexPage;
